@import "~@coreui/coreui/scss/coreui.scss";
@import "variables";

.map {
    position: absolute;
    width: 125%;
    height: 200%;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    &-container{
        min-height: 20rem;
    }
}

.abs-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.hospital-logo {
    object-fit: contain;
    object-position: center;
    width: 100px;
}

.hospital-logo-big {
    position: absolute;
    z-index: 99;
    width: 25%;
    background: white;
    padding: 16px;
}